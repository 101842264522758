import Grid from "@mui/material/Grid";
import NotLoggedInLayout from "../layouts/NotLoggedInLayout";
import Paper from "@mui/material/Paper";
import React, {useMemo} from "react";
import Typography from "@mui/material/Typography";
import {Helmet} from "react-helmet-async";
import AppLogo from "../shared/AppLogo";
import useNotLoggedInLayoutPaper from "../../hooks/useNotLoggedInLayoutPaper";
import WarningIcon from "@mui/icons-material/WarningAmber";
import usePaymentLink from "../../hooks/usePaymentLink";
import Link from "@mui/material/Link";
import removeIdPrefix from "../../utils/hashids";
import {THIS_APP_URL} from "../../config";

const PaymentErrorPage = () => {

    const layout = useNotLoggedInLayoutPaper();

    const linkID = usePaymentLink();

    const linkToCopy = useMemo(() => {
        if (linkID) {
            return `${THIS_APP_URL}/pay/${removeIdPrefix(linkID)}`;
        } else {
            return "";
        }
    }, [linkID]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Payment Error</title>
            </Helmet>
            <NotLoggedInLayout>
                <Paper
                    elevation={layout.elevation}
                    sx={{
                        maxWidth: "420px",
                        width: "420px",
                        ...layout.paddings,
                        ...layout.margins,
                    }}
                >
                    <Grid container direction="column" spacing={1}>
                        <Grid item width={"100%"} sx={{textAlign: "center"}}>
                            <AppLogo/>
                        </Grid>
                        <Grid item mt={5}>
                            <Typography textAlign="center" variant="h2">
                                Something went wrong...
                            </Typography>
                        </Grid>
                        <Grid item textAlign="center" mt={4}>
                            <WarningIcon
                                style={{fill: "#ff0000", width: "52px", height: "52px"}}
                            />
                        </Grid>
                        <Grid item textAlign="center" mb={2}>
                            <Typography variant={"body1"}>
                                Your payment didn't go through. Please try again later.
                            </Typography>
                        </Grid>
                        {linkID && (
                            <Grid item textAlign="center" mb={2}>
                                <Link
                                    underline="hover"
                                    href={linkToCopy}
                                    noWrap
                                    color={"GrayText"}
                                    fontSize={16}
                                    display={"block"}
                                >
                                    {`\u2190`} Back to the payment page
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </NotLoggedInLayout>
        </React.Fragment>
    );
};

export default PaymentErrorPage;
