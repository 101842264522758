import { deleteApiRequest, getApiRequest, putApiRequest } from "..";
import User, {Achievement} from "../../model/User";
import PaymentsAmount from "../../model/PaymentsAmount";

export const retrieveMeApiRoute = async () => {
  return (await getApiRequest(`me`)) as User;
};

export const listMyStatsApiRoute = async () => {
  return (await getApiRequest(`me/stats`)) as PaymentsAmount[];
};

export interface UpdateMeRequest {
  firstName?: string;
  lastName?: string;
  avatar?: string; // @NOTE: Should be a file name.
  lang?: string;
  whatsappPhone?: string;
  reviewLink?: string;
}

export const updateMeRoute = async (request: UpdateMeRequest) => {
  return (await putApiRequest("me", request)) as User;
};

export const deleteMeApiRoute = async () => {
  await deleteApiRequest("me");
};

export const listMyAchievementsRoute = async () => {
  return (await getApiRequest(`achievements`)) as Achievement[];
};
