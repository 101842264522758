import {StandardTextFieldProps} from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { formatCurrencyCodeSign } from "../../model/PaymentLink";
import {APP_ALLOW_SELECT_CURRENCY} from "../../config";

interface CurrencySelectProps extends StandardTextFieldProps {
    onFormat?: (code: string, sign: string) => void;
    currValue: CurrencyType;
    label:string
}

export interface CurrencyType {
    code: string;
    label: string;
    sign:  string;
    suggested?: boolean;
    preset1: string;
    preset2: string;
    preset3: string;
    minAmount: number;
    maxAmount: number;
}

const currencies: readonly CurrencyType[] = [
    { code: 'EUR', label: 'Euro', sign: formatCurrencyCodeSign('EUR'), preset1: '5', preset2: '10', preset3: '15', minAmount: 1, maxAmount: 100 },
    { code: 'GBP', label: 'Great Britain Pound', sign: formatCurrencyCodeSign('GBP'), preset1: '5', preset2: '10', preset3: '15', minAmount: 1, maxAmount: 100},
    { code: 'TRY', label: 'Turkish Lira', sign: formatCurrencyCodeSign('TRY'), preset1: '30', preset2: '60', preset3: '150', minAmount: 5, maxAmount: 200},
    { code: 'IDR', label: 'Indonesian Rupiah', sign: formatCurrencyCodeSign('IDR'), preset1: '10000', preset2: '15000', preset3: '20000', minAmount: 10000, maxAmount: 50000},
    { code: 'RSD', label: 'Serbian dinar', sign: formatCurrencyCodeSign('RSD'), preset1: '30', preset2: '60', preset3: '150', minAmount: 5, maxAmount: 200},
];

export const getCurrency = (code: string) => {
    if (code && code.length > 0) {
        return currencies.filter(value => value.code === code)[0];
    } else {
        return currencies[0];
    }
}

export default function CurrencySelect(props: CurrencySelectProps) {

    const { onFormat, currValue, ...otherProps } = props;

    return (
        <Autocomplete<CurrencyType>
            id="currency-select"
            sx={otherProps.sx}
            disabled={otherProps.disabled || !APP_ALLOW_SELECT_CURRENCY}
            value={currValue}
            options={currencies}
            onChange={ (e, obj) => {
                if (onFormat && obj ){
                    const item = obj as CurrencyType
                    onFormat(item.code, item.sign);
                }
            }}
            autoHighlight
            getOptionLabel={(option) =>  option.label }
            renderOption={(otherProps, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...otherProps}>
                   {option.sign} {option.label} ({option.code})
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={otherProps.label}
                    required={otherProps.required}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}



