import React from "react";
import Avatar from "@mui/material/Avatar";
import noUserAvatarUrl from "../../images/no-user-avatar.svg";

interface UserAvatarProps {
  url?: string;
  size?: "tiny" | "small" | "medium" | "large";
  onClick?: () => void;
}

const UserAvatar = ({ url, size, onClick }: UserAvatarProps) => {
  let sizeInPixels;
  let borderInPixels;

  switch (size) {
    case "tiny":
      sizeInPixels = 48;
      borderInPixels = 2;
      break;
    case "small":
      sizeInPixels = 64;
      borderInPixels = 3;
      break;
    case "large":
      sizeInPixels = 192;
      borderInPixels = 5;
      break;
    default:
      sizeInPixels = 120;
      borderInPixels = 5;
  }

  return (
    <Avatar
      src={url}
      onClick={onClick}
      sx={{
        background: url ? "white" : "#DFDFDF",
        borderWidth: `${borderInPixels}px`,
        width: sizeInPixels,
        height: sizeInPixels,
      }}
    >
      <img
        style={{ width: "65%", height: "65%" }}
        src={noUserAvatarUrl}
        alt={""}
      />
    </Avatar>
  );
};

export default UserAvatar;
