import React, {useCallback, useEffect, useState} from "react";
import useNotLoggedInLayoutPaper from "../../hooks/useNotLoggedInLayoutPaper";
import {Helmet} from "react-helmet-async";
import usePayment from "../../hooks/usePayment";
import {
    getOVOPayStatusApiRoute,
    OVOPaymentStatusResponse
} from "../../api/routes/paymentRoutes";
import {useHistory} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AppLogo from "../shared/AppLogo";
import Typography from "@mui/material/Typography";
import NotLoggedInLayout from "../layouts/NotLoggedInLayout";
import OVOCircularWithValueLabel from "../shared/OVOCircularProgressWithLabel";

const PaymentWaitPage = () => {

    const layout = useNotLoggedInLayoutPaper();

    const payID = usePayment();

    const history = useHistory();

    const [timeRemaining, setTimeRemaining] = useState<number>(80) // https://jokul.doku.com/docs/docs/jokul-direct/e-money/ovo-guide/

    const [progressValue, setProgressValue] = useState<number>(0)

    const [ovoPaymentStatus, setOvoPaymentStatus] = useState<OVOPaymentStatusResponse | undefined>(undefined);

    const loadOVOPaymentStatusLink = useCallback(async () => {
        try {
            if (payID) {
                const resp = await getOVOPayStatusApiRoute(payID);
                setOvoPaymentStatus(resp);
            }
        } catch (error: any) {
            console.log(`Can't load payment info: ${error.message}`);
        }
    }, [payID]);

    useEffect(() => {
        const timer = setTimeout(() => {
            loadOVOPaymentStatusLink().then();
        }, 1000);
        return () => clearTimeout(timer);
    }, [loadOVOPaymentStatusLink]);

    useEffect(() => {
        const timer = setTimeout(() => {
            loadOVOPaymentStatusLink().then();
        }, 20000);
        return () => clearTimeout(timer);
    }, [loadOVOPaymentStatusLink]);

    useEffect(() => {
        const timer = setTimeout(() => {
            loadOVOPaymentStatusLink().then();
        }, 75000);
        return () => clearTimeout(timer);
    }, [loadOVOPaymentStatusLink]);

    useEffect(() => {
        if (ovoPaymentStatus) {
            if (ovoPaymentStatus.status === "Done") {
                window.location.replace(ovoPaymentStatus.paymentSuccessLink);
            } else if (ovoPaymentStatus.status === "Error") {
                window.location.replace(ovoPaymentStatus.paymentFailLink);
            }
        }
    }, [ovoPaymentStatus, history])

    const countdown = useCallback(async (timer) => {
        let remaining = timeRemaining - 1;
        if (remaining <= 1) {
            clearInterval(timer);
            setTimeRemaining(0);
            setProgressValue(100);
            if (ovoPaymentStatus) {
                window.location.replace(ovoPaymentStatus.paymentFailLink);
            } else {
                history.push("/");
            }
        } else {
            setTimeRemaining(remaining);
            let progress = Math.round((80 - remaining) / 80 * 100);
            setProgressValue(progress);
        }
    }, [timeRemaining, history, ovoPaymentStatus]);

    useEffect(() => {
        const timer = setInterval(() => {
            countdown(timer).then();
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [countdown]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Payment is on process</title>
            </Helmet>
            <NotLoggedInLayout>
                <Paper
                    elevation={layout.elevation}
                    sx={{
                        maxWidth: "420px",
                        width: "420px",
                        ...layout.paddings,
                        ...layout.margins,
                    }}
                >
                    <Grid container direction="column" spacing={1}>
                        <Grid item width={"100%"} sx={{textAlign: "center"}}>
                            <AppLogo/>
                        </Grid>
                        <Grid item mt={5}>
                            <Typography textAlign="center" variant="h2">
                                Processing your payment...
                            </Typography>
                        </Grid>
                        <Grid item textAlign="center" mt={4}>
                            <OVOCircularWithValueLabel value={progressValue} seconds={timeRemaining}/>
                        </Grid>
                        <Grid item textAlign="center" mb={2}>
                            <Typography variant={"body1"}>
                                Your payment is on process, please wait.
                            </Typography>
                        </Grid>
                        {ovoPaymentStatus && (
                            <Grid item textAlign="center" mb={2}>
                                <Typography variant={"body1"}  color={"#FFFFFF"}>
                                    {ovoPaymentStatus.externalID}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </NotLoggedInLayout>
        </React.Fragment>
    )
}

export default PaymentWaitPage;