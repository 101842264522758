import App from "./components/App";
import Fade from "@material-ui/core/Fade";
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {AppContext, rootStore} from "./store";
import {HelmetProvider} from "react-helmet-async";
import {SnackbarProvider} from "notistack";
import "./base.css";
import {ReactComponent as SuccessIcon} from "./images/success.svg";
import WarningIcon from "@mui/icons-material/WarningAmber";
import InitialLoading from "./components/shared/InitialLoading";

ReactDOM.render(
    <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        TransitionComponent={Fade as any}
        classes={{
            variantSuccess: "Snackbar-success",
            variantError: "Snackbar-error",
        }}
        iconVariant={{
            success: <SuccessIcon style={{width: "32px", height: "32px"}}/>,
            error: <WarningIcon sx={{width: "32px", height: "32px"}}/>,
        }}
    >
        <React.StrictMode>
            <HelmetProvider>
                <AppContext.Provider value={rootStore}>
                    <Suspense fallback={<InitialLoading/>}>
                        <App/>
                    </Suspense>
                </AppContext.Provider>
            </HelmetProvider>
        </React.StrictMode>
    </SnackbarProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
