import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from 'i18next-http-backend';

export const getDefaultI18nextInstance = () => {
    void i18n
        .use(HttpApi)
        .use(initReactI18next)
        .init({
            detection: {
                order: [
                    "querystring",
                    "cookie",
                    "sessionStorage",
                    "",
                    "navigator",
                    "htmlTag",
                ],
            },
            defaultNS: ["dashboard_org", "dashboard_user", "translation"],
            fallbackNS: "translation",
            fallbackLng: "EN",
            interpolation: {
                escapeValue: false,
            },
            react: {
                useSuspense: false,
            },
   });
   return i18n;
}

export const i18nUsers = getDefaultI18nextInstance();

export const i18nPayments = i18nUsers
    .cloneInstance({
        defaultNS: 'payments',
    })
    .use(HttpApi);


