import Grid from "@mui/material/Grid";
import NotLoggedInLayout from "../layouts/NotLoggedInLayout";
import Paper from "@mui/material/Paper";
import React, {useCallback, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Helmet} from "react-helmet-async";
import AppLogo from "../shared/AppLogo";
import useNotLoggedInLayoutPaper from "../../hooks/useNotLoggedInLayoutPaper";
import {ReactComponent as SuccessIcon} from "../../images/success.svg";
import Box from "@mui/material/Box";
import {ReactComponent as GorillasLogoImg} from "../../images/gorillas-logo-img-secondary.svg";
import {ReactComponent as GophrLogoImg} from "../../images/gophr-logo-img.svg";
import {Link} from "@mui/material";
import {ThemeProvider} from "@mui/material";
import mainTheme from "../themes/mainTheme";
import gophrPaymentTheme from "../themes/payments/gophrPaymentTheme";
import gorillasPaymentTheme from "../themes/payments/gorillasPaymentTheme";
import usePayment from "../../hooks/usePayment";
import {getPaymentReviewLinkApiRoute} from "../../api/routes/paymentRoutes";

interface PaymentSuccessPageProps {
    paymentFormTheme?: string;
}

const PaymentSuccessPage = ({paymentFormTheme = "main"}: PaymentSuccessPageProps) => {

    const layout = useNotLoggedInLayoutPaper();
    const payID  = usePayment();

    let customPaymentTheme = false;
    let customTheme = mainTheme;

    if (paymentFormTheme === "gorillas") {
        customTheme = gorillasPaymentTheme;
        customPaymentTheme = true;
    } else if (paymentFormTheme === "gophr") {
        customTheme = gophrPaymentTheme;
        customPaymentTheme = true;
    }

    const [reviewLink, setReviewLink] = useState<string | undefined>(undefined);

    const loadPaymentReviewLink = useCallback(async () => {
        try {
            if (payID) {
                const resp = await getPaymentReviewLinkApiRoute(payID);
                setReviewLink(resp.reviewLink);
            }
        } catch (error: any) {
            console.log(`Can't load payment link review info: ${error.message}`);
        }
    }, [payID]);

    useEffect(() => {
        loadPaymentReviewLink().then();
    }, [loadPaymentReviewLink]);


    return (
        <ThemeProvider theme={customTheme}>
            <React.Fragment>
                <Helmet>
                    <title>Payment Success!</title>
                </Helmet>
                <NotLoggedInLayout>
                    <Paper
                        elevation={layout.elevation}
                        sx={{
                            maxWidth: "420px",
                            width: "420px",
                            ...layout.paddings,
                            ...layout.margins,
                        }}
                    >
                        <Grid container direction="column" spacing={1}>
                            <Grid item width={"100%"} sx={{textAlign: "center"}}>
                                <AppLogo/>
                            </Grid>
                            <Grid item mt={customPaymentTheme ? 2 : 5}>
                                <Typography textAlign="center" variant="h2">
                                    Thank You!
                                </Typography>
                            </Grid>
                            {customPaymentTheme && (
                                <Grid item>
                                    <Typography textAlign="center" variant="body1">
                                        Payment has been successfully sent! Our couriers thank you!
                                    </Typography>
                                </Grid>
                            )}
                            {customPaymentTheme ? (
                                <Box
                                    component={"span"}
                                    sx={{
                                        fontSize: customPaymentTheme ? 24 : 56,
                                        fontWeight: "bold",
                                        borderTop: "1px solid #424242",
                                        margin: "0 -40px",
                                        mt: 3,
                                        pt: 3,
                                    }}
                                >
                                    <Typography textAlign="center" variant="body1">
                                        {paymentFormTheme === "gorillas" && (
                                            <>
                                                Click <Link
                                                href="http://gorillas.go.link?&adj_t=k3w65f8&adj_campaign=bra_pship_NL_iTips_Utrecht&adj_adgroup=other">here</Link> to
                                                explore thousand of products at supermarket prices.
                                            </>
                                        )}
                                        {paymentFormTheme === "gophr" && (
                                            <>
                                                Click <Link href="">here</Link> to explore thousand of products at
                                                supermarket prices.
                                            </>
                                        )}
                                    </Typography>

                                    <Grid item textAlign="center" mt={2}>
                                        {paymentFormTheme === "gorillas" && (
                                            <Link
                                                href="http://gorillas.go.link?&adj_t=k3w65f8&adj_campaign=bra_pship_NL_iTips_Utrecht&adj_adgroup=other">
                                                <GorillasLogoImg/>
                                            </Link>
                                        )}
                                        {paymentFormTheme === "gophr" && (
                                            <Link
                                                href="http://gorillas.go.link?&adj_t=k3w65f8&adj_campaign=bra_pship_NL_iTips_Utrecht&adj_adgroup=other">
                                                <GophrLogoImg/>
                                            </Link>
                                        )}
                                    </Grid>
                                </Box>
                            ) : (
                                <>
                                    <Grid item textAlign="center" mt={4}>
                                        <SuccessIcon style={{width: "52px", height: "52px"}}/>
                                    </Grid>
                                    <Grid item textAlign="center" mb={2}>
                                        <Typography variant={"body1"}>
                                            Your payment was successful.
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            {reviewLink && (
                                <Grid item textAlign="center" >
                                    <React.Fragment>
                                        You can leave {" "}
                                        <Link href={reviewLink} underline={"hover"}>
                                            a review.
                                        </Link>
                                    </React.Fragment>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                </NotLoggedInLayout>
            </React.Fragment>
        </ThemeProvider>
    );
};

export default PaymentSuccessPage;
