import styled from 'styled-components';
import LogoSrc from '../../images/icon-apple-pay.png';

const IconAppplePay = styled.img.attrs({
    src: `${LogoSrc}`
})`
width: 98px;
height: 40px;
`;

export default IconAppplePay;
