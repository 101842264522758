import {StandardTextFieldProps} from "@mui/material/TextField/TextField";
import TextField from "@mui/material/TextField";
import React, {useEffect, useRef, useState} from "react";
import {InputAdornment} from "@mui/material";

type State = {
    oldCaretPosition: number;
    formattedOVOId: string;
    oldFormattedOVOId: string;
};

interface Props extends StandardTextFieldProps {
    onFormat?: (value: string) => void;
}

const OVOIdInput = (props: Props) => {

    const { onFormat, ...otherProps } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const [state, setState] = useState<State>({
        oldCaretPosition: 0,
        formattedOVOId: "",
        oldFormattedOVOId: "",
    });

    useEffect(() => {
        const { oldCaretPosition, formattedOVOId, oldFormattedOVOId } =
            state;

        let caretPosition = oldCaretPosition;
        const lengthDifference =
            formattedOVOId.length - oldFormattedOVOId.length;

        if (lengthDifference > 0) {
            caretPosition = caretPosition - lengthDifference;
        }

        const lastChar = formattedOVOId.charAt(
            formattedOVOId.length - 1
        );

        if (lastChar === ")") {
            inputRef.current!.setSelectionRange(
                formattedOVOId.length - 1,
                formattedOVOId.length - 1
            );
        } else if (
            caretPosition > 0 &&
            oldFormattedOVOId.length >= formattedOVOId.length
        ) {
            inputRef.current!.setSelectionRange(caretPosition, caretPosition);
        }
    }, [state]);

    return (
        <TextField
            {...otherProps}
            inputRef={inputRef}
            InputProps={{
                startAdornment: <InputAdornment position="start">08</InputAdornment>,
                sx: {
                    "& .MuiInputBase-input": {
                        paddingTop: "16px",
                    },
                },
            }}
            value={state.formattedOVOId}
            onChange={(e) => {
                let phoneNumber = e.target.value || "";

                // Does exceed default 10 digit phone number limit.
                phoneNumber = phoneNumber.replace(/\D/g, "");
                if (phoneNumber.length > 10) {
                    return;
                }

                if (phoneNumber === state.formattedOVOId) {
                    return;
                }

                const nextFormattedOVOId = phoneNumber;

                setState({
                    oldCaretPosition: e.target.selectionStart!,
                    oldFormattedOVOId: state.formattedOVOId,
                    formattedOVOId: nextFormattedOVOId,
                });

                if (onFormat) {
                    onFormat(phoneNumber);
                }
            }}
        />
    );
}

export default OVOIdInput;