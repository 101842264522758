import Grid from "@mui/material/Grid";
import NotLoggedInLayout from "../layouts/NotLoggedInLayout";
import Icon404 from "../../images/404.svg";
import React from "react";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet-async";
import { NavLink, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import useRootStore from "../../hooks/useRootStore";

const NotFoundPage = () => {
  const activeUserStore = useRootStore().activeUserStore;
  const history = useHistory();

  const urlToRedirectTo = activeUserStore.me ? "/" : "/login";

  return (
    <React.Fragment>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <NotLoggedInLayout>
        <Grid container direction="column" spacing={0} pb={8} pt={6}>
          <Grid
            item
            container
            direction={"row"}
            spacing={2}
            flexGrow={0}
            p={4}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={6} textAlign={"right"}>
              <img
                style={{ width: "100%", maxWidth: "460px" }}
                src={Icon404}
                alt="404"
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={12}
              sm={6}
              maxWidth={"540px"}
            >
              <Grid item>
                <Typography variant="h2">Page Not Found</Typography>
              </Grid>
              <Grid item mt={4}>
                <Typography variant="body2">
                  Извините, но такой страницы нет на сайте. Возможно, вы ввели
                  неправильный адрес, или страница удалена.
                </Typography>
              </Grid>
              <Grid item mt={5}>
                <Button
                  variant={"contained"}
                  component={NavLink}
                  to={urlToRedirectTo}
                  onClick={(event: any) => {
                    event.preventDefault();
                    history.replace(urlToRedirectTo);
                  }}
                >
                  Go to Home Page
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </NotLoggedInLayout>
    </React.Fragment>
  );
};

export default NotFoundPage;
