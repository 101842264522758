import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";

const InitialLoading = () => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      flexGrow={1}
      justifyContent="center"
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
export default InitialLoading;
