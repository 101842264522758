import React from "react";
import Chip from "@mui/material/Chip";

interface ChipComponentProps {
  label: string;
  onClick?: () => void;
  clickable?: boolean;
  sx?: object;
}

const ChipComponent = ({label, onClick, clickable, sx}: ChipComponentProps) => {
  return (
    <Chip
      onClick={onClick}
      label={label}
      clickable={clickable}
      sx={sx}
    />
  )
}

export default ChipComponent;
