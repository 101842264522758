import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import queryString from "query-string";

const usePayment = () => {

    const location = useLocation();

    const [payID, setPayID] = useState<string | undefined>(undefined);

    useEffect(() => {
        const params = queryString.parse(location.search);
        if (params.payID && typeof params.payID === "string") {
            setPayID(params.payID as string);
        }
    },[location.search])

    return payID
}

export default usePayment;