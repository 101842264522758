import { useMediaQuery, useTheme } from "@mui/material";

type NotLoggedInLayoutPaperPadding = {
  pt: number;
  pb: number;
  pl: number;
  pr: number;
};

type NotLoggedInLayoutPaperMargin = {
  ml: number;
  mr: number;
};

type NotLoggedInLayoutPaper = {
  elevation: number;
  paddings: NotLoggedInLayoutPaperPadding;
  margins: NotLoggedInLayoutPaperMargin;
};

const useNotLoggedInLayoutPaper = () => {
  const theme = useTheme();
  const notMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const result: NotLoggedInLayoutPaper = {
    elevation: notMobile ? 1 : 0,
    paddings: {
      pt: notMobile ? 4 : 0,
      pb: notMobile ? 4 : 0,
      pl: notMobile ? 8 : 4,
      pr: notMobile ? 8 : 4,
    },
    margins: {
      ml: notMobile ? 4 : 2,
      mr: notMobile ? 4 : 2,
    },
  };
  return result;
};

export default useNotLoggedInLayoutPaper;
