import React from "react";
import {useTheme} from "@mui/material";
import styled from "@emotion/styled";

interface RatingStarProps {
  selected?: boolean;
  onClick?: () => void;
}

const RatingStarStyled = styled.svg<RatingStarProps>`
  cursor: pointer;
  opacity: ${(props) => (props.selected ? 1.0 : 0.3)};

  &:hover {
    opacity: ${(props) => (props.selected ? 1.0 : 0.6)};
  }
`;
const RatingStar = ({selected, onClick}: RatingStarProps) => {
  return (
    <RatingStarStyled
      width="53"
      height="50"
      viewBox="0 0 53 50"
      selected={selected}
      onClick={onClick}
    >
      <path
        d="M26.5 0L32.6741 19.002H52.6541L36.49 30.7459L42.6641 49.748L26.5 38.0041L10.3359 49.748L16.51 30.7459L0.345945 19.002H20.3259L26.5 0Z"
        fill={useTheme().palette.primary.main}
      />
    </RatingStarStyled>
  )
}

export default RatingStar;

