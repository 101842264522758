import React from "react";
import {Helmet} from "react-helmet-async";
import NotLoggedInLayout from "../layouts/NotLoggedInLayout";
import Grid from "@mui/material/Grid";
import UnknownQRCodeSrc from "../../images/unknown_qr_code.svg";
import Typography from "@mui/material/Typography";

const UnknownPaymentLink = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Unknown payment link</title>
            </Helmet>
            <NotLoggedInLayout>
                <Grid container direction="column" spacing={0} pb={8} pt={6}>
                    <Grid
                        item
                        container
                        direction={"row"}
                        spacing={2}
                        flexGrow={0}
                        flexShrink={1}
                        p={4}
                        alignItems={"center"}
                    >
                        <Grid item xs={12} sm={6} textAlign={"right"}>
                            <img
                                style={{width: "100%", maxWidth: "320px"}}
                                src={UnknownQRCodeSrc}
                                alt="404"
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            xs={12}
                            sm={6}
                            maxWidth={"540px"}
                        >
                            <Grid item>
                                <Typography variant="h2">
                                    This payment link is not active.
                                </Typography>
                            </Grid>
                            <Grid item mt={4}>
                                <Typography variant="body2">
                                    If you got the payment link by scanning a QR code then
                                    please try to scan it again.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </NotLoggedInLayout>
        </React.Fragment>
    );
}

export default UnknownPaymentLink;