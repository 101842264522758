import {ReactComponent as LightLogo} from "../../images/itips_logo_light.svg";
import {ReactComponent as DarkLogo} from "../../images/itips_logo_dark.svg";
import {Theme, useTheme} from "@mui/material";
import {CSSProperties, SVGProps} from "react";

interface AppLogoProps {
    size?: "normal" | "small" | "tiny";
    marginLeft?: number;
}

const AppLogo = ({size, marginLeft}: AppLogoProps) => {
    const theme = useTheme<Theme>();

    if (!size) {
        size = "normal";
    }

    const svgProps: SVGProps<SVGSVGElement> = {
        role: "img",
    };

    const style: CSSProperties = {
        marginLeft: marginLeft || "0",
        width: size === "normal" ? "232px" : size === "tiny" ? "96px" : "152px",
    };

    return theme.palette.mode === "dark" ? (
        <LightLogo style={style} {...svgProps} />
    ) : (
        <DarkLogo style={style} {...svgProps} />
    );
};

export default AppLogo;
