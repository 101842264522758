import {createTheme} from "@mui/material";
import mainTheme from "../mainTheme";

const gophrPaymentTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#8f48ff",
    },
    text: {
      primary: "#ffffff"
    }
  },
  typography: {
    fontFamily: mainTheme.typography.fontFamily,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#000000",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontFamily: ["Arial", "sans-serif"].join(","),
          fontSize: 15,
          [mainTheme.breakpoints.up("sm")]: {
            fontSize: 16,
          },
        },
        h2: {
          fontSize: 30,
          fontWeight: "bold",
          [mainTheme.breakpoints.up("sm")]: {
            fontSize: 24,
          },
        },
        h3: {
          fontSize: 21,
          fontWeight: "bold",
          [mainTheme.breakpoints.up("sm")]: {
            fontSize: 24,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 25,
          background: "#292929",
          "& fieldset": {
            borderColor: "#424242",
          },
          "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
            border: `1px solid #8f48ff`,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#8f48ff",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          paddingTop: 12,
          paddingBottom: 12,
          fontSize: 16,
          borderRadius: 25,
          background: "#292929",
          border: "1px solid #424242",
          height: "auto",
          "&:hover": {
            background: "#8f48ff",
            border: "1px solid #8f48ff",
            color: "#ffffff",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          paddingLeft: "2rem",
          paddingRight: "2rem",
          borderRadius: 25,
          textTransform: "none",
          boxShadow: "none",
          color: "#ffffff",
          ":hover": {
            boxShadow: "none",
          },
          [mainTheme.breakpoints.up("sm")]: {
            fontSize: 20,
          },
        },
      },
    },
  },
});

export default createTheme(gophrPaymentTheme);
