const useDetectAndroid = () => {
    return detectIsAndroid()
}

export function detectIsAndroid() {
    // @ts-ignore
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android/i.test(userAgent);
}

export default useDetectAndroid;