import styled from 'styled-components';
import LogoSrc from '../../images/icon-pay-card.png';

const IconPayCard = styled.img.attrs({
    src: `${LogoSrc}`
})`
width: 122px;
height: 40px;
`;

export default IconPayCard;
