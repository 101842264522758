import Grid from "@mui/material/Grid";
import BlurBackgroundImage from "../../images/color_blur.png";
import Box from "@mui/material/Box";
import React from "react";

type NotLoggedInLayoutProps = {
  children: React.ReactNode;
};

const NotLoggedInLayout = ({ children }: NotLoggedInLayoutProps) => {
  return (
    <Box
      component={Grid}
      container
      item
      direction={"column"}
      flexGrow={1}
      justifyContent="stretch"
      alignItems="stretch"
      spacing={0}
      sx={{
        height: "100%",
        bgcolor: "background.default",
        backgroundPosition: "calc(-140vh + 10vw) calc(-140vh + 10vw)",
        backgroundSize: "220vh",
        backgroundImage: `url(${BlurBackgroundImage})`,
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      }}
    >
      <Box
        component={Grid}
        container
        item
        direction={"column"}
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{
          height: "100%",
          overflow: "auto",
          backgroundPosition: "calc(100vw - 125vh) 0vh",
          backgroundSize: "220vh",
          backgroundImage: `url(${BlurBackgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: "none",
          pt: 3,
          pb: 3,
          scrollbarColor: "rgba(163,163,163,255) rgba(0, 0, 0, 0) ",
        }}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          flexGrow={1}
          flexShrink={1}
          justifyContent="center"
        >
          {children}
        </Grid>
      </Box>
    </Box>
  );
};
export default NotLoggedInLayout;
