import PaymentLink from "../../model/PaymentLink";

import {
  deleteApiRequest,
  getApiRequest,
  postApiRequest,
  putApiRequest,
} from "..";

export const getPaymentLinkApiRoute = async (id: string) => {
  return (await getApiRequest(`payment-links/${id}`)) as PaymentLink;
};

export const listPaymentLinksApiRoute = async () => {
  return (await getApiRequest(`payment-links`)) as PaymentLink[];
};

export interface UpdatePaymentLinkRequest{
  description: string;
  paymentText?: string;
  presetAmount1?: number;
  presetAmount2?: number;
  presetAmount3?: number;
  establishmentId?: string;
  allowsEmployeeSelection?: boolean;
  qrCodeUserName?: string;
  currencyCode: string;
  lang: string;
}

export interface CreatePaymentLinkRequest extends UpdatePaymentLinkRequest  {}

export const createPaymentLinkRoute = async (
  request: CreatePaymentLinkRequest
) => {
  return (await postApiRequest("payment-links", request)) as PaymentLink;
};

export const updatePaymentLinkRoute = async (
  linkId: string,
  request: UpdatePaymentLinkRequest
) => {
  return (await putApiRequest(
    `payment-links/${linkId}`,
    request
  )) as PaymentLink;
};

export const deletePaymentLinkApiRoute = async (linkId: string) => {
  return await deleteApiRequest(`payment-links/${linkId}`);
};

