import React, {useMemo} from "react";
import PaymentLink, {formatCurrencyCodeSign, isLongCurrency} from "../../model/PaymentLink";
import Grid from "@mui/material/Grid";
import ChipComponent from "./ChipComponent";

export type OptionalPresetAmount = number | undefined;

interface PaymentAmountPresentsProps {
  paymentLink: PaymentLink;
  onAmountSelected?: (amount: number) => void;
}

const PaymentAmountPresets = ({
                                paymentLink,
                                onAmountSelected,
                              }: PaymentAmountPresentsProps) => {

  const longCurrencyValue = useMemo(() => {
    return isLongCurrency(paymentLink.currencyCode);
  }, [paymentLink]);

  if (
    !paymentLink.presetAmount1 &&
    !paymentLink.presetAmount2 &&
    !paymentLink.presetAmount3
  ) {
    return null;
  }


  const presetAmounts: OptionalPresetAmount[] = [
    paymentLink.presetAmount1,
    paymentLink.presetAmount2,
    paymentLink.presetAmount3,
  ];

  return (
    <Grid
      item
      mt={0}
      container
      direction="row"
      sx={{flex: "1 1 auto"}}
      rowSpacing={1}
      columnSpacing={2}
    >
      {presetAmounts.map((amount, index) => {
        if (!amount) {
          return null;
        }
        return (
          <Grid item key={index} xs={4} flexGrow={0} flexShrink={0}>
            <ChipComponent
              label={`${amount} ${formatCurrencyCodeSign(paymentLink?.currencyCode)}`}
              clickable
              onClick={() => {
                if (onAmountSelected) {
                  onAmountSelected(amount);
                }
              }}
              sx={{
                width: "100%",
                fontSize: longCurrencyValue ? 12 : 16,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PaymentAmountPresets;