import {Helmet} from "react-helmet-async";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useSnackbar} from "notistack";
import {useRouteMatch} from "react-router-dom";
import PaymentLink from "../../model/PaymentLink";
import {getPaymentLinkApiRoute} from "../../api/routes/paymentLinkRoutes";
import UnknownPaymentLink from "../shared/UnknownPaymentLink";
import InitialLoading from "../shared/InitialLoading";
import {TextField, ThemeProvider, useMediaQuery, useTheme} from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AppLogo from "../shared/AppLogo";
import mainTheme from "../themes/mainTheme";
import Establishment from "../../model/Establishment";
import User from "../../model/User";
import UserAvatar from "../shared/UserAvatar";
import EstablishmentLogo from "../shared/EstablishmentLogo";
import Typography from "@mui/material/Typography";
import RatingStar from "../shared/RatingStar";
import NotLoggedInLayout from "../layouts/NotLoggedInLayout";
import {ImpressionEmoji, impressionsData} from "../shared/PaymentForm";
import {useTranslation} from "react-i18next";
import {i18nPayments} from "../locale/i18n";
import LoadingButton from "@mui/lab/LoadingButton";
import {postPaymentCommentRoute} from "../../api/routes/paymentRoutes";

interface MatchParams {
    id?: string;
    pay?: string;
}

const PaymentCommentPage = () => {

    const {enqueueSnackbar} = useSnackbar();

    const {t, i18n} = useTranslation('payments', {i18n: i18nPayments});

    const paymentLinkPartialId = useRouteMatch<MatchParams>().params.id;

    const paymentPartialId = useRouteMatch<MatchParams>().params.pay;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [paymentReceiver, setPaymentReceiver] = useState<Establishment | User | undefined>(undefined);

    const [paymentLink, setPaymentLink] = useState<PaymentLink | undefined>(
        undefined
    );

    const commentInput = useRef<HTMLInputElement>(null);

    const [rating, setRating] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (paymentLink?.establishment) {
            setPaymentReceiver(paymentLink?.establishment);
        } else if (paymentLink?.user) {
            setPaymentReceiver(paymentLink?.user);
        } else {
            setPaymentReceiver(undefined);
        }
    }, [
        paymentLink
    ]);

    const loadPaymentLink = useCallback(async () => {
        if (!paymentLinkPartialId) {
            setIsLoading(false);
            return;
        }
        try {
            const paymentLinkId = "link_" + paymentLinkPartialId;
            const fetchedPaymentLink = await getPaymentLinkApiRoute(paymentLinkId);
            setPaymentLink(fetchedPaymentLink);
            if (fetchedPaymentLink?.establishment) {
                setPaymentReceiver(fetchedPaymentLink?.establishment);
            } else if (fetchedPaymentLink?.user) {
                setPaymentReceiver(fetchedPaymentLink?.user);
            }
            await i18n.changeLanguage(fetchedPaymentLink.lang)
        } finally {
            setIsLoading(false);
        }
    }, [paymentLinkPartialId, i18n]);


    const theme = useTheme();

    const notMobile = useMediaQuery(theme.breakpoints.up("sm"));

    let customTheme = mainTheme;

    useEffect(() => {
        loadPaymentLink().then();
    }, [loadPaymentLink]);

    const renderFormContent = useCallback(() => {

        const updatePaymentLink = async () => {

            const paymentLinkId = "link_" + paymentLinkPartialId;
            const payId = "pay_" + paymentPartialId;

            const request = {
                id: paymentLinkId,
                pay: payId,
                comment: commentInput.current!.value || undefined,
                rating: rating,
            };

            setIsSubmitting(true);

            try {
                await postPaymentCommentRoute(request);
                window.location.replace("/payment-success");
            } catch (error: any) {
                enqueueSnackbar(error.message, {
                    variant: "error",
                });
            } finally {
                setIsSubmitting(false);
            }
        }

        if (paymentReceiver) {

            const receiverIsUser = "firstName" in paymentReceiver!;

            return (
                <React.Fragment>
                    <Grid item alignSelf={"center"}>
                        {receiverIsUser ? (
                            <UserAvatar url={paymentReceiver!.avatar}/>
                        ) : (
                            <EstablishmentLogo url={paymentReceiver!.logo}/>
                        )}
                    </Grid>
                    <>
                        <Grid item mt={2}>
                            <Typography variant={"h3"} align={"center"}>
                                Did you like everything?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"row"}
                            justifyContent={"space-between"}
                            flexWrap={"nowrap"}
                            spacing={0}
                            mt={1}
                        >
                            {[1, 2, 3, 4, 5].map((ratingValue) => (
                                <Grid item key={ratingValue}>
                                    <RatingStar
                                        selected={!!rating && rating >= ratingValue}
                                        onClick={() =>
                                            setRating(
                                                rating === ratingValue ? undefined : ratingValue
                                            )
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item mt={1}>
                            <TextField
                                InputProps={{
                                    sx: {
                                        "& .MuiInputBase-input": {
                                            textAlign: "center",
                                        },
                                        "& ::-webkit-input-placeholder": {
                                            textAlign: "center",
                                        },
                                        "& :-moz-placeholder": {
                                            textAlign: "center",
                                        },
                                        "& ::-moz-placeholder": {
                                            textAlign: "center",
                                        },
                                        "& :-ms-input-placeholder": {
                                            textAlign: "center",
                                        },
                                    },
                                }}
                                fullWidth
                                id="comment"
                                autoComplete="off"
                                placeholder={"You can leave a comment"}
                                multiline
                                inputRef={commentInput}
                            />
                        </Grid>
                        {/*{formErrors.comment && (*/}
                        {/*    <Grid item xs={12}>*/}
                        {/*        <Typography color={"rgb(211, 47, 47)"} variant={"body2"}*/}
                        {/*                    align={"center"}>*/}
                        {/*            {formErrors.comment}*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}
                        {/*)}*/}
                        <Grid item mt={2}>
                            <Typography variant={"body1"} align={"center"}>
                                {t("link.impression")}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"row"}
                            justifyContent={"space-between"}
                            flexWrap={"nowrap"}
                            spacing={0}
                        >
                            {[1, 2, 3, 4, 5].map((ratingValue) => (
                                <Grid item key={ratingValue} flexBasis={"20%"}>
                                    <ImpressionEmoji
                                        imageUrl={impressionsData[ratingValue - 1].url}
                                        imageUrl2x={impressionsData[ratingValue - 1].url2x}
                                        selected={!!rating && rating >= ratingValue}
                                        onClick={() =>
                                            setRating(
                                                rating === ratingValue ? undefined : ratingValue
                                            )
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item mt={2}>
                            <LoadingButton
                                size="large"
                                fullWidth
                                variant="contained"
                                onClick={updatePaymentLink}
                                loading={isSubmitting}
                            >
                                {t("payment.comment")}
                            </LoadingButton>
                        </Grid>
                    </>

                </React.Fragment>
            )
        }
    }, [paymentReceiver, setRating, rating, isSubmitting, t, enqueueSnackbar, paymentLinkPartialId, paymentPartialId])

    if (!isLoading && !paymentLink) {
        return (
            <UnknownPaymentLink/>
        )
    } else {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Leave a comment</title>
                </Helmet>
                <NotLoggedInLayout>
                    <div style={{maxWidth: "430px", width: "100%"}}>
                        {isLoading ? (
                            <InitialLoading/>
                        ) : (
                            <ThemeProvider theme={customTheme}>
                                <Paper
                                    elevation={notMobile ? 1 : 0}
                                    sx={{
                                        pt: notMobile ? 4 : 0,
                                        pb: 4,
                                        pl: notMobile ? 8 : 4,
                                        pr: notMobile ? 8 : 4,
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Grid container direction="column" spacing={1} flexShrink={1}>
                                        <Grid item textAlign={"center"} mb={2} zIndex={1}>
                                            <AppLogo size={"small"}/>
                                        </Grid>
                                        {renderFormContent()}
                                    </Grid>
                                </Paper>
                            </ThemeProvider>
                        )}
                    </div>
                </NotLoggedInLayout>
            </React.Fragment>
        )
    }
}

export default PaymentCommentPage;