import styled from 'styled-components';
import LogoSrc from '../../images/icon-google-pay.png';

const IconSbpPay = styled.img.attrs({
    src: `${LogoSrc}`
})`
width: 84px;
height: 40px;
`;

export default IconSbpPay;
