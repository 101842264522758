import { makeAutoObservable } from "mobx";
import User from "../model/User";
import { retrieveMeApiRoute } from "../api/routes/meRoutes";

export default class ActiveUserStore {
  jwt: string | null;
  me?: User;

  constructor() {
    makeAutoObservable(this);
    this.jwt = localStorage.getItem("jwt");
  }

  clear() {
    this.jwt = null;
    this.me = undefined;
    localStorage.removeItem("jwt");
  }

  storeAuthSuccess(jwt: string, me: User) {
    this.jwt = jwt;
    this.me = me;
    localStorage.setItem("jwt", jwt);
  }

  storeMe(me: User) {
    this.me = me;
  }

  async fetch() {
    this.storeMe(await retrieveMeApiRoute());
  }
}
