import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import queryString from "query-string";

const usePaymentLink = () => {

    const location = useLocation();

    const [linkID, setLinkID] = useState<string | undefined>(undefined);

    useEffect(() => {
        const params = queryString.parse(location.search);
        if (params.linkID && typeof params.linkID === "string") {
            setLinkID(params.linkID as string);
        }
    },[location.search])

    return linkID
}

export default usePaymentLink;