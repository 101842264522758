import NoEstablishmentLogoIcon from "../../images/no-establishment-logo.svg";
import Avatar from "@mui/material/Avatar";

interface EstablishmentLogoProps {
  url?: string;
  size?: "large" | "medium" | "smallish" | "small";
}

const EstablishmentLogo = ({ url, size }: EstablishmentLogoProps) => {
  let borderSizeInPixels = 5;
  let sizeInPixels;

  switch (size) {
    case "large": {
      sizeInPixels = 192;
      break;
    }
    case "smallish":
      sizeInPixels = 112;
      break;
    case "small":
      sizeInPixels = 80;
      borderSizeInPixels = 3;
      break;
    case "medium":
    default:
      sizeInPixels = 120;
  }

  return (
    <Avatar
      variant={"rounded"}
      alt={"Logo"}
      src={url || undefined}
      sx={{
        width: sizeInPixels,
        height: sizeInPixels,
        borderWidth: `${borderSizeInPixels}px`,
      }}
    >
      <img
        alt={"No logo"}
        style={{ width: "65%", height: "65%" }}
        src={NoEstablishmentLogoIcon}
      />
    </Avatar>
  );
};

export default EstablishmentLogo;
