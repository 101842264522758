import {
  maxPresetPaymentAmount,
  minPresetPaymentAmount,
} from "../model/PaymentLink";

export const isBlankString = (value: string | undefined) => {
  return value === undefined || value === null || value.length === 0;
};

export const validateNonBlankString = (
  value: string | undefined,
  messageWhenBlank: string
) => {
  if (isBlankString(value)) {
    return messageWhenBlank;
  }
  return null;
};

export const validateEmail = (email: string | undefined) => {
  if (isBlankString(email)) {
    return "Please enter an email";
  }
  const regexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  if (!regexp.test(email!)) {
    return "Invalid email format";
  }
  return null;
};

export const validateStrippedPhoneNumber = (
  phoneNumber: string | undefined
) => {
  if (isBlankString(phoneNumber)) {
    return "Please enter a phone number";
  }
  if (phoneNumber!.length <= 7) {
    return "Phone number is too short!";
  }
  return null;
};

export const validatePassword = (password: string | undefined) => {
  if (password === undefined || password == null || password === "") {
    return "Please enter a password";
  }
  if (password.length < 6) {
    return "A password must have a least 6 characters";
  }
  if (password.length >= 24) {
    return "A password must have no more than 24 characters";
  }
  if (!password.match(/[A-Za-z]/g)) {
    return "A password must contain at least one latin letter";
  }
  if (!password.match(/\d/g)) {
    return "A password must contain at least one digit";
  }
  return null;
};

export const validateSEPAAccount = (
    value: string | undefined,
    messageWhenBlank: string
) => {
  if (isBlankString(value)) {
    return messageWhenBlank;
  }
  if (value!.length < 18 || value!.length > 35 ) {
    return "SEPA Account number length is between 18 and 35 symbols";
  }
  return null;
};

export const validateDigitSequence = (
  text: string | undefined,
  length: number,
  messageWhenBlank: string
) => {
  if (text === undefined || text == null || text === "") {
    return messageWhenBlank;
  }
  if (!text.match(/\d/g)) {
    return "Must contain only digits";
  }
  if (text.length !== length) {
    return `Must have ${length} digits`;
  }
  return null;
};

export const validatePasswordConfirmation = (
  confirmation: string | undefined,
  password: string | undefined
) => {
  if (
    confirmation === undefined ||
    confirmation == null ||
    confirmation === ""
  ) {
    return "Please confirm a password";
  }
  if (confirmation !== password) {
    return "Passwords don't match";
  }
  return null;
};

export const validatePresetPaymentAmountString = (amountString?: string) => {
  if (isBlankString(amountString)) {
    return "Must not be blank";
  }
  const amount = parseInt(amountString!, 10);
  if (isNaN(amount)) {
    return "Must be a number";
  }
  if (!isInt(amountString!)){
    return "Must be integer number"
  }
  if (amount < minPresetPaymentAmount || amount > maxPresetPaymentAmount) {
    return `Must be from 3€ to 100€`;
  }
  return null;
};

export const validatePayoutAmountString = (amountString?: string) => {
  if (isBlankString(amountString)) {
    return "Must not be blank";
  }
  const amount = parseInt(amountString!, 10);
  if (isNaN(amount)) {
    return "Must be a number";
  }
  if (amount < 1) {
    return "Minimal transfer amount is 1€"
  }
  return null;
}

function isInt(n:any) {
  return n % 1 === 0;
}


export const validateNonBlankNumber = (
    numberValue: number | undefined,
    messageWhenBlank: string
) => {
  if (numberValue === undefined || numberValue === null || isNaN(numberValue)) {
    return messageWhenBlank;
  }
  return null;
};

export const validateInviteJob = (job: string | undefined) => {
  if (job === undefined || job == null || job === "") {
    return "Please enter the employee's job description";
  }
    if (job.length > 20) {
    return "Employee's job description must have no more than 20 characters";
  }
  if (!job.match(/[A-Za-z]/g)) {
    return "A employee's job description must contain at least one latin letter";
  }
  return null;
};

export const validatePresetPaymentAmountStringCurrency = (t: any, minPreset: number, maxPreset: number, sign: string, amountString?: string) => {
  if (isBlankString(amountString)) {
    return `${t("qr.error.blank")}`; // "Must not be blank"
  }
  const amount = parseInt(amountString!, 10);
  if (isNaN(amount)) {
    return `${t("qr.error.number")}`; // "Must be a number"
  }
  if (!isInt(amountString!)){
    return `${t("qr.error.integer")}`; // "Must be integer number"
  }
  if (amount < minPreset || amount > maxPreset) {
    return `${t("qr.error.border")} ${minPreset}${sign} - ${maxPreset}${sign}`; // `Must be from 3€ to 100€`
  }
  return null;
};

export const validateReviewLink = (
    value: string | undefined
) => {
  if (value && value.length > 1000 ) {
    return "Review link should not be more than 1000 symbols";
  }
  return null;
};

export const validateWhatsappPhoneNumber = (
    value: string | undefined
) => {
  if (value && value.length <= 7) {
    return "Phone number is too short!";
  }
  return null;
}
