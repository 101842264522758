// See public/env.js and Dockerfile/docker-entrypoint.sh.
// @ts-ignore
export const API_URL = window.API_URL;
// @ts-ignore
export const THIS_APP_URL = window.THIS_APP_URL;
// @ts-ignore
export const APP_ALLOW_SELECT_CURRENCY = window.APP_ALLOW_SELECT_CURRENCY === "true";
// @ts-ignore
export const APP_ALLOW_SELECT_COUNTRY = window.APP_ALLOW_SELECT_COUNTRY === "true";
// @ts-ignore
export const APP_DEFAULT_CURRENCY = window.APP_DEFAULT_CURRENCY;
// @ts-ignore
export const APP_DEFAULT_COUNTRY = window.APP_DEFAULT_COUNTRY;
